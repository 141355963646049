<template>
  <!-- Desc: View: product & category targeting for - Prouct targeting adgroups  -->
  <div
    class="adGroupsKeywordTargeting card min-height-card u-flex-direction-column u-width-100 u-display-flex"
    :style="styleVariables"
  >
    <component
      :is="addCustomKeywordPanelConfig.ui_component"
      v-if="showAddCustomKeywordPanel"
      v-bind="addCustomKeywordPanelConfig.props"
      v-on="addCustomKeywordPanelConfig.events"
    >
      <div
        v-if="showErrorMessage"
        slot="right-table-footer"
        class="edit_suggested_campaign_keyword_targeting u-font-size-6 u-color-orange-base u-font-weight-600 u-position-absolute"
      >
        <rb-icon
          class="u-color-orange-base rb-icon--small"
          icon="info-circle-fill"
        />
        <span>
          {{ showErrorMessage }}
        </span>
      </div>
    </component>
    <div v-if="adjustBids">
      <div class="u-font-size-6 u-spacing-p-l">
        <div class="u-spacing-pb-xs u-text-case-upper u-font-weight-600">
          ADJUST BID ({{ selections.length }} KEYWORD{{
            selections.length > 1 ? `'s` : ''
          }}
          selected)
        </div>
        <radioSelectionWithInput
          class="u-spacing-pt-m"
          :radio-config="bidChangeConfig"
          default-radio-selected="set_new_bid"
          :error-message="errorMessage"
          :show-input-title="showInputTitle"
          :bid-suggestion="bidSuggestion"
          @onUpdate="onBidUpdates"
        />
      </div>
      <div
        class="u-spacing-pv-m u-border-top u-border-color-grey-xxx-light u-border-width-s"
      >
        <footerButtons
          :disable-left-btn="
            !(bidValue && bidValue.inputValue && bidValue.inputValue.length) ||
            errorMessage.length > 0
          "
          class="u-spacing-ph-l"
          :button-size="'s'"
          :hollow-hover-button-left="false"
          left-button-type="filled"
          btn-left-text="Apply"
          @onClickBtnRight="closeActionBar"
          @onClickBtnLeft="onSaveBid"
        />
      </div>
    </div>
    <div
      v-if="confirmStatusChange"
      class="u-spacing-pt-l"
    >
      <component
        :is="confirmStatusChange.component"
        v-bind="confirmStatusChange.props(this)"
      />
    </div>
    <div
      v-if="selections.length && !adjustBids && !confirmStatusChange"
      class="u-spacing-pt-l"
    >
      <actions-bar
        :close-fn="closeActionBar"
        :selections="selections"
        :recommendation-type="''"
        :passed-actions="computedAllowedActions"
        @open-workspace="openActionsWorkspace"
      />
    </div>
    <dashboard-inflator
      class="u-width-100"
      :class="selections.length && 'campaign_details_table_header_padding'"
      :page="pageInfo"
      :request-params="requestParams"
      :page-context="context"
      :widget-level-settings="headerOptions"
      :show-header="false"
      :disable-envelop-width-class="true"
      @metadataUpdated="metadataUpdated"
      @widgetParamsCollectionUpdated="widgetParamsCollectionUpdated"
    />
  </div>
</template>
<script>
import Vue from 'vue';
import dashboardServiceTable from '@/components/widgets/custom_widgets/custom_widget_components/tables/dashboardServiceTable.vue';
import CustomRecommendations from '@/pages/dashboard_service_campaign_management/common_components/custom-recommendation.vue';
import customActionPanel from '@/components/ams/actionPanels/customActionPanel.vue';
import dashboardMixin from '@/components/widgets/custom_widgets/dashboard_mixin.js';
import radioSelectionWithInput from '@/components/pages/instacart/campaigns/campaigns/adjustBudget/radioSelectionWithInput.vue';
const actionsBar = () => import('@/components/widgets/actionsBar.vue');
const footerButtons = () =>
  import('@/pages/entity_details/common_components/footerButtons.vue');
const reviewDataComp = () =>
  import('@/pages/entity_details/configs/walmart/components/reviewData.vue');
const confirmStatusChangeComponent = () =>
  import(
    '@/pages/dashboard_service_campaign_management/common_components/approveRec.vue'
  );
const reviewStatusComponent = () =>
  import('@/pages/entity_details/configs/walmart/components/reviewStatus.vue');

const commonRequestParams = (vueRef) => ({
  [vueRef.tabMetadata?.widgets?.[0]]: {
    ':widget': vueRef.tabMetadata?.widgets?.[0],
    ':orderByList': [],
    ':dimensionNameValueList': [],
    keepExistingDimensionNameValueList: true,
    replaceEntityInDimensionNameValueList: {
      ':adgroup_id': vueRef.entityId,
      ':ad_group_id': vueRef.entityId
    },
    replaceEntityInOperationsObject: {
      ':system': `campaign_${vueRef.parentEntityId}`
    }
  }
});

export default {
  components: {
    actionsBar,
    radioSelectionWithInput,
    footerButtons,
    CustomRecommendations
  },
  mixins: [dashboardMixin],
  props: {
    dateFilter: {
      default: () => {},
      type: Object
    },
    tabMetadata: {
      default: () => {},
      type: Object
    },
    entityConfig: {
      default: () => {},
      type: Object
    },
    entityId: {
      default: null,
      type: String
    },
    parentEntityId: {
      default: null,
      type: String
    }
  },
  data() {
    const pageInfo = {
      name: this.tabMetadata?.page
    };
    const context = this;
    return {
      errorMessage: '',
      showInputTitle: false,
      pageInfo,
      initRequestParams: {
        global: {
          ...pageInfo,
          ':page': pageInfo.name,
          ':entityType': '#ALLOVER_AGGREGATE'
        }
      },
      recFilter: '',
      recCustomComponent:
        this.entityConfig?.tabs[this?.tabMetadata?.component]
          ?.recCustomComponent,
      showRecCustomComponent: false,
      preferNoRecFilterList: [
        'RECOMMENDATIONS_KEYWORDS_FOR_SKUS',
        'RECOMMENDATIONS_KEYWORDS_EFFICIENT_SEARCHTERMS',
        'RECOMMENDATIONS_KEYWORDS_CAMPAIGN_BESTPRACTICE'
      ],
      selectedRecommendation: {},
      isCustomActionStateVisible: false,
      context,
      bidValue: null,
      showAddCustomKeywordPanel: false,
      bidChangeConfig: [
        {
          title: 'Set new bid',
          type: 'input',
          value: 'set_new_bid'
        },
        {
          title: 'Increase bid',
          value: 'increase_bid',
          type: 'dropdown',
          dropdown: [
            {
              label: 'Change by percentage',
              value: 'percentage'
            },
            {
              label: 'Change absolute value',
              value: 'absolute_value'
            }
          ]
        },
        {
          title: 'Decrease bid',
          value: 'decrease_bid',
          type: 'dropdown',
          dropdown: [
            {
              label: 'Change by percentage',
              value: 'percentage'
            },
            {
              label: 'Change absolute value',
              value: 'absolute_value'
            }
          ]
        }
      ],
      getState: null,
      selections: [],
      adjustBids: null,
      stateChangeRequestPayload: null,
      bidChangeRequestPayload: null,
      updateMetadata: null,
      tableGridAPI: null,
      actionIdMethodMap: {},
      allowedActions: [
        {
          icon: 'task-status',
          title: 'EDIT STATUS',
          id: 'editStatus',
          dropDown: true,
          dropDownOptions: [
            { title: 'Enable', value: 'enabled', color: 'u-color-green-base' },
            { title: 'Pause', value: 'paused', color: 'u-color-orange-base' },
            { title: 'Archive', value: 'archived', color: 'u-color-red-base' }
          ]
        },
        {
          icon: 'dollar',
          title: 'Adjust Bid',
          id: 'adjustBids'
        }
      ],
      bidSuggestion: { show: false },
      tabConfig: null,
      recommendationWidgetParams: {},
      recommendationMetadata: {},
      reviewData: null,
      showErrorMessage: null,
      confirmStatusChange: null,
      widgetsMetaData: []
    };
  },
  computed: {
    computedAllowedActions() {
      if (this.$store.getters.getRetailer === 'instacart') {
        return this.allowedActions.map((action) =>
          action.id === 'adjustBids' &&
          this.selections.some(
            (sel) => sel.data.instacart_cm_targeting_bid_type === 'default'
          )
            ? {
                ...action,
                disabled: true,
                tippy: 'Action set limited for keywords with default bid type'
              }
            : action
        );
      } else {
        return this.allowedActions;
      }
    },
    tableDataComponentKey() {
      return this.$store.getters[
        this.tabConfig?.apiConfigs?.getTableDataComponentKeyGetter
      ];
    },
    getAdGroupInfo() {
      return this.$store.getters[this.tabConfig?.apiConfigs?.getter];
    },
    campaignInfo() {
      return this.$store.getters[this.getState];
    },
    headerOptions() {
      const btnText = this.addCustomKeywordPanelConfig.props.btnText || '';
      const obj = {};
      const that = this;
      const isReviewDataVisible =
        this?.tabConfig?.headerOptions?.(this).length > 0;
      const displayType = isReviewDataVisible ? 'flex' : 'grid';
      const metaDataOfWidget = this.widgetsMetaData?.[0];
      obj[this.tabMetadata?.widgets?.[0]] = {
        props: {
          headerOptions: [
            {
              ui_component: 'rb-button',
              props: {
                clickFn: that.addCustomKeywordButtonClick,
                text: `Add ${btnText} Keywords`,
                type: 'filled',
                disabled: this.campaignInfo?.data?.reviewStatus === 'pending'
              },
              class: 'u-spacing-mr-xl'
            },
            ...(this?.tabConfig?.headerOptions?.(this) || [])
          ],
          enableDownload: false,
          enableManageColumns: !this.selections.length,
          manageColumnGridPosition: !isReviewDataVisible ? 4 : undefined,
          manageColumnFlexOrder: isReviewDataVisible ? 1000 : 300,
          headerContainerGridStyles: {
            'grid-template-columns': 'auto auto 1fr',
            display: this.selections.length ? 'none' : displayType
          },
          searchParams: {
            show: true,
            placeholder: 'Find a keyword',
            searchDimension:
              metaDataOfWidget?.metadata?.searchKey ||
              this.tabConfig?.searchText
          },
          rowSelection: {
            mode: 'multiple',
            onSelectionChanged: this.handleSelectionChange
          },
          rowHeight: 45
        }
      };
      return obj;
    },
    addCustomKeywordPanelConfig() {
      return this.tabConfig?.addCustomKeywordPanelConfig?.(
        this,
        this.campaignInfo?.data?.adGroupId,
        undefined
      );
    },
    styleVariables() {
      return {
        '--bottomPadding': this.tabConfig?.bottomPadding || '11.5rem'
      };
    }
  },
  watch: {
    dateFilter: {
      handler(newVal) {
        this.initRequestParams.global = {
          ...this.initRequestParams.global,
          ...newVal
        };
        const initRequestParams = {
          ...this.initRequestParams,
          ...commonRequestParams(this)
        };
        if (initRequestParams.instacart_cm_adgroup_fp_tab_keyword) {
          initRequestParams.instacart_cm_adgroup_fp_tab_keyword[
            ':orderByList'
          ] = [
            { dimension: 'instacart_cm_targeting_spend', direction: 'DESC' }
          ];
        }
        this.updateEntireRequestParams(initRequestParams);
      },
      immediate: true,
      deep: true
    },
    tableDataComponentKey: function (nv, ov) {
      const globalRequestParams = this.getGlobalRequestParams();
      this.updateGlobalRequestParams({ ...globalRequestParams, key: nv });
      if (nv !== ov) {
        this.tabConfig?.apiConfigs?.fetchMetadata?.(this);
      }
    }
  },
  created() {
    Vue.component(this.tabMetadata?.widgets?.[0], dashboardServiceTable);
    Vue.component('customActionPanel', customActionPanel);
    Vue.component('reviewDataComp', reviewDataComp);
    Vue.component('confirmStatusChangeComponent', confirmStatusChangeComponent);
    Vue.component('reviewStatusComponent', reviewStatusComponent);
    const initRequestParams = {
      ...this.initRequestParams,
      ...commonRequestParams(this)
    };
    if (initRequestParams.instacart_cm_adgroup_fp_tab_keyword) {
      initRequestParams.instacart_cm_adgroup_fp_tab_keyword[':orderByList'] = [
        { dimension: 'instacart_cm_targeting_spend', direction: 'DESC' }
      ];
    }
    if (this.$store.getters.getRetailer === 'walmart') {
      initRequestParams[this.tabMetadata.widgets[0]][':orderByList'] = [
        { dimension: 'walmart_cm_keyword_ad_spend', direction: 'DESC' }
      ];
    }
    this.updateEntireRequestParams(initRequestParams);
    const tabConfig = this.entityConfig?.tabs[this?.tabMetadata?.component];
    this.tabConfig = tabConfig;
    this.getState = tabConfig?.apiConfigs?.getter;
    this.stateChangeRequestPayload = tabConfig?.stateChange?.requestPayload;
    this.bidChangeRequestPayload = tabConfig?.bidChange?.requestPayload;
    this.updateMetadata = tabConfig?.apiConfigs?.updateAction;
    this.tabConfig?.apiConfigs?.fetchMetadata?.(this);
    if (tabConfig.actionType === 'instacartAdgroupMetadataChange') {
      this.showInputTitle = true;
      this.allowedActions = [
        { icon: 'dollar', title: 'Adjust Bid', id: 'adjustBids' }
      ];
    }
    if (tabConfig.bidChange === undefined) {
      this.allowedActions = [
        {
          icon: 'task-status',
          title: 'EDIT STATUS',
          id: 'editStatus',
          dropDown: true,
          dropDownOptions: [
            { title: 'Enable', value: 'enabled', color: 'u-color-green-base' },
            { title: 'Pause', value: 'paused', color: 'u-color-orange-base' },
            { title: 'Archive', value: 'archived', color: 'u-color-red-base' }
          ]
        }
      ];
    }
  },
  methods: {
    widgetParamsCollectionUpdated(paramsCollection) {
      if (paramsCollection?.adgroup_sp_tab_keyword_recommendation) {
        this.recommendationWidgetParams =
          paramsCollection?.adgroup_sp_tab_keyword_recommendation;
      }
    },
    metadataUpdated(data) {
      this.widgetsMetaData = data;
      let recommendationMetadata = {};
      data.forEach((item) => {
        if (item.name === 'adgroup_sp_tab_keyword_recommendation') {
          recommendationMetadata = item;
        }
      });
      this.recommendationMetadata = recommendationMetadata;
    },
    handleSelectionChange(item1, item2) {
      this.tableGridAPI = item2?.api;
      this.selections = item1;
      this.tabConfig?.checkForSuggestedBid?.(this);
    },
    transformBid(oldBid, selection, key) {
      let delta = parseFloat(this.bidValue.inputValue);
      if (this.bidValue.radioValue === 'set_new_bid') {
        if (this.bidValue.inputValue === 'YY') {
          return parseFloat(oldBid);
        }
        if (this.bidValue.inputValue === 'XX') {
          return parseFloat(selection.data?.[key]);
        }
        return delta;
      }
      if (this.bidValue?.dropdownValue === 'percentage') {
        delta = oldBid * (delta / 100);
      }
      if (this.bidValue?.radioValue === 'increase_bid') {
        return parseFloat((oldBid + delta).toFixed(2));
      } else {
        return parseFloat((oldBid - delta).toFixed(2));
      }
    },
    onBidUpdates(updatedValues) {
      this.bidValue = updatedValues;
      this.selections.map((sel) => {
        const oldBid = sel?.data?.instacart_cm_targeting_latest_bid || 0;
        let newBid = this.transformBid(
          oldBid,
          sel,
          'instacart_cm_targeting_suggested_bid'
        );
        if (
          ((updatedValues?.inputValue && !newBid) ||
            parseFloat(newBid) < 0.3 ||
            parseFloat(newBid) > 85) &&
          this.tabConfig.actionType === 'instacartAdgroupMetadataChange'
        ) {
          this.errorMessage =
            'Bid should be between $0.3 and $85, both inclusive.';
        } else {
          this.errorMessage = '';
        }
      });
    },
    openActionsWorkspace(
      actionId,
      removeSelectionState,
      selectionLength,
      dropDownSelection,
      selections
    ) {
      if (actionId === 'editStatus') {
        this.confirmStatusChange = {
          component: 'confirmStatusChangeComponent',
          props: (vueRef) => ({
            config: {
              heading: `EDIT STATUS(${vueRef.selections.length} item${
                vueRef.selections.length > 1 ? 's' : ''
              } selected)`,
              leftBtnLabel: 'Apply',
              rightBtnLabel: 'Cancel',
              message: `Status for selected item${
                vueRef.selections.length > 1 ? 's' : ''
              } will be ${dropDownSelection[0].value}`,
              apply: () => {
                this.callStateChange(dropDownSelection, selections);
              },
              handleCancel: () => {
                this.closeActionBar();
              }
            }
          })
        };
      } else {
        if (actionId === 'adjustBids') {
          this.tabConfig?.checkForSuggestedBid?.(this);
        }
        this[actionId] = true;
      }
    },
    closeActionBar() {
      this.tableGridAPI?.deselectAll();
      this.selections = [];
      this.adjustBids = null;
      this.bidSuggestion = { show: false };
      this.confirmStatusChange = null;
    },
    callStateChange(dropDownSelection, selections) {
      return this.tabConfig?.changeStatus?.(
        this,
        dropDownSelection,
        selections
      );
    },
    onSaveBid() {
      return this.tabConfig?.onSaveBid?.(this);
    },
    updateMetadataAction(payloadArr, entityName) {
      this.$store.dispatch(this.updateMetadata, {
        updateRequest: payloadArr,
        entityId: this.entityId,
        entityType: this.entityConfig.entityType,
        snackbar: this.$snackbar,
        callAdgroupFetch: false,
        entityName,
        routeData: this.$route
      });
      this.closeActionBar();
      this.showAddCustomKeywordPanel = false;
    },
    checkForSuggestedBid() {
      this.bidSuggestion = { show: false };
      this.bidValue = null;
      if (
        this.selections.length === 1 &&
        this.selections[0]?.data?.walmart_cm_targeting_bid_suggested
      ) {
        this.bidSuggestion = {
          show: true,
          title: 'Walmart Suggested Bid',
          value:
            this.selections[0]?.data?.walmart_cm_targeting_bid_suggested.toString(),
          currentBid:
            this.selections[0]?.data?.walmart_cm_targeting_bid.toString(),
          multipleSuggestions: false
        };
      } else if (
        this.selections.length === 1 &&
        this.selections[0]?.data?.instacart_cm_targeting_suggested_bid
      ) {
        this.bidSuggestion = {
          show: true,
          title: 'Instacart Suggested Bid',
          value:
            this.selections[0]?.data?.instacart_cm_targeting_suggested_bid.toString(),
          currentBid:
            this.selections[0]?.data?.instacart_cm_targeting_latest_bid.toString(),
          multipleSuggestions: false
        };
      } else if (this.selections.length > 1) {
        let showBidSuggestion = true;
        this.selections.map((selection, index) => {
          if (!selection.data.walmart_cm_targeting_bid_suggested) {
            showBidSuggestion = false;
          } else if (!selection.data.instacart_cm_targeting_suggested_bid) {
            showBidSuggestion = false;
          }
        });
        this.bidSuggestion = {
          show: showBidSuggestion,
          title: 'Amazon Suggested Bid',
          value: 'XX',
          currentBid: 'YY',
          multipleSuggestions: showBidSuggestion,
          currentBidNote: 'YY - Respective current bid in the table below',
          suggestedBidNote:
            'XX - Respective Amazon suggested bid in the table below'
        };
      } else {
        this.adjustBids = false;
      }
    },
    toggleRecCustomComponent(val) {
      this.showRecCustomComponent = val;
    },
    handleRecommendation(val) {
      this.selectedRecommendation = val;
    },
    // event listener to capture the action state in actions bar
    toggleCustomActionState(val) {
      this.isCustomActionStateVisible = val;
    },
    recommendationFilter(val, label) {
      if (val) {
        const [value] = Object.values(val);
        this.recFilter = { value, label };
        if (this.preferNoRecFilterList.includes(value)) {
          this.toggleRecCustomComponent(true);
        }
      } else {
        this.recFilter = '';
      }
    },
    addCustomKeywordButtonClick() {
      this.closeActionBar();
      this.showAddCustomKeywordPanel = true;
    },
    reloadComponent(boolReload = true) {
      if (boolReload) {
        this.$store.dispatch(
          this.tabConfig?.apiConfigs?.fetchTableDataComponentKeyGetter
        );
      }
    }
  }
};
</script>
<style lang="css">
.adGroupsKeywordTargeting .edit_suggested_campaign_keyword_targeting {
  bottom: var(--bottomPadding);
}
.adGroupsKeywordTargeting .inputClassForEntityTaggingSearch {
  display: flex;
  align-items: center;
  height: 100%;
}
.adGroupsKeywordTargeting .recommendations-container .header-container-cwc {
  padding: 0px;
}
.adGroupsKeywordTargeting .min-height-card {
  min-height: 630px;
}
.adGroupsKeywordTargeting .header-container-cwc {
  padding: 2.4rem;
}
.adGroupsKeywordTargeting
  .count-class
  > .u-display-flex.u-text-overflow-ellipsis::after {
  content: 'recommendations';
  font-family: 'ProximaNova', sans-serif;
  font-size: 1.1rem;
  font-weight: 600;
  margin-left: 0.4rem;
  margin-top: 0.5rem;
}
.adGroupsKeywordTargeting .order-1 {
  order: 1;
}
.adGroupsKeywordTargeting .order-2 {
  order: 2;
}
.adGroupsKeywordTargeting .order-3 {
  order: 3;
}
.adGroupsKeywordTargeting .order-4 {
  order: 4;
}
.adGroupsKeywordTargeting .dashboard-service-table-wrapper {
  margin: 0;
  padding-bottom: 0;
}

.adGroupsKeywordTargeting div[col-id^='walmart_cm_keyword_status'].ag-cell {
  margin: 0;
  padding: 0;
}
.campaign_details_table_header_padding .header-container-cwc {
  padding: 0px;
}
</style>

<style lang="css" scoped>
.min-height-card {
  min-height: 630px;
}
</style>
